import { detectFeatureToggles, asString, asBoolean } from '@rio-cloud/rio-uikit/urlFeatureToggles';

import { useUserAccount } from '../login/loginSlice';

// Define old toggles to clean up
const oldToggles = ['ft_eventTeaser', 'ft_survey', 'ft_menu', 'ft_groupedPois', 'ft_featureVoting'];

// Define toggle transformation functions with their respective names and
// export the detected feature toggles as a singleton
export const featureToggles = detectFeatureToggles(
    {
        enforcedEnv: asString('ft_env'),
        enforcedLocale: asString('ft_enforceLocale'),
        enforcedLocalWidget: asString('ft_localWidget'),
        tracing: asBoolean('ft_tracing'),
        support: asBoolean('ft_support'),
        demo: asBoolean('ft_demo'),
        simulateGeoBooked: asString('ft_simulateGeoBooked'),
        smartRoutePlanning: asBoolean('ft_smartRoutePlanning'),
        smartRoutePlanningBooking: asBoolean('ft_srpBooking'),
        devMode: asBoolean('ft_devMode'),
        skipChristmas: asBoolean('skipChristmas'),
        platformNotifications: asBoolean('ft_platformNotifications'),
    },
    oldToggles
);

export const useWhiteListedFeatureToggles = (whiteList: string[] = []) => {
    const accountId = useUserAccount();
    return whiteList.includes(accountId);
};

export const useSmartRouteFeatureToggle = () => {
    const smartRouteWhiteList = [
        'mockaccount',
        '8ef04c71-8105-4bfb-a97c-a70db9d66975', // "RIO VIT"
        '237c58e3-60b5-4442-a9ec-8641ea39325b', // "Lastkraftwagenbauer"
        '284b3c1f-72c1-4669-a51b-c7ca9cc3c967', // “Demofleet MAN Sales”
        'ac464fce-f700-49a7-aa13-60b0c5c3f9ce', // “Testaccount MAN Truck & Bus SE”
        '0eaa962f-ddf9-4280-9d37-3d0760959493', // "EEV-Trucktesting"
        'be0fa59d-6b42-4025-9039-1c1ac8efb4e1', // MAN Truck and Bus SE
        '0ff74f0d-c891-47ca-a7e6-9501be388fe7', // LoadFox
        '482df194-8e57-4e45-ba4d-691ad2d21908', // Beta Customer - requested by "Felix Kremser"
        '3d8eac7c-d6f4-422e-90bc-a6fa7778e9f0', // Beta Customer - requested by "Felix Kremser"
        'a8ade106-59f4-4d7d-822f-790adcf1de89', // MAN Truck & BUS UK LTD - requested by "Maximilian Nicolussi-Moretto"
        'a350fdb9-9af5-4123-a0dc-e6b2c61c8ae7', // MAN truck & bus B.V. - requested by "Maximilian Nicolussi-Moretto"
        '1cea77ad-5ce2-4452-b0af-6cae14ab5f5f', // MAN Truck & Bus Vertrieb Österreich GesmbH - requested by "Felix Kremser"
        '3a3f27f1-c499-477b-8a21-bb0a09d6556b', // MAN Truck & Bus Deutschland GmbH - Vorführflotte DACH - requested by "Felix Kremser"
    ];
    return useWhiteListedFeatureToggles(smartRouteWhiteList);
};

// Additional feature toggles just for charging stations
export const useChargingStationsFeatureToggle = () => {
    const whiteList = [
        // 'mockaccount',
        'be0fa59d-6b42-4025-9039-1c1ac8efb4e1', // MAN Truck & Bus SE - requested by "Phillip Walter"
        '7283d2d7-88f3-46ee-828e-01ee81d7f2b7', // MAN Truck & Bus Iberia - requested by "Phillip Walter"
        '15a7d09e-6b01-42e3-ad11-08df2c6a4bad', // MAN Truck & Bus ITALIA SPA - requested by "Phillip Walter"
        '3d8eac7c-d6f4-422e-90bc-a6fa7778e9f0', // MAN Truck & Bus Commerce - requested by "Phillip Walter"
        '482df194-8e57-4e45-ba4d-691ad2d21908', // MAN Truck & Bus BE - requested by "Phillip Walter"
        '3a3f27f1-c499-477b-8a21-bb0a09d6556b', // MAN Truck & Bus Deutschland GmbH - requested by "Phillip Walter"
        '0ff74f0d-c891-47ca-a7e6-9501be388fe7', // LoadFox Transport Solutions GmbH - requested by "Phillip Walter"
        '9809ca36-ca31-4bd3-b53a-be403f9d03c9', // Traton Charging Solutions - requested by "Phillip Walter"
        'b7522019-a092-43da-8387-ce8825685ca2', // Traton Charging Solutions Test AB - requested by "Phillip Walter"
        '284b3c1f-72c1-4669-a51b-c7ca9cc3c967', // Demofleet MAN Sales - requested by "Phillip Walter"
        '1beca9f3-f75f-4fa2-95c5-a3615e41acfd', // Wellpappe Alzenau GmbH & Co. KG - requested by "Phillip Walter"
        'ca2a4f5b-77ac-481c-b4b1-948454040ca1', // Nanno Janssen GmbH - requested by "Phillip Walter"
        '0e56bf54-0b15-47ba-a2cc-c8f63cd9f124', // Vorführflotte DACH - requested by "Maximilian Nicolussi-Moretto" and "Phillip Walter",
        'a8ade106-59f4-4d7d-822f-790adcf1de89', // MAN Truck & BUS UK LTD - requested by "Maximilian Nicolussi-Moretto"
        'a50f6048-efb9-4e6a-a478-d9d7864d6bcd', // DB Schenker Deutschland AG - requested by "Maximilian Nicolussi-Moretto"
        '2984c8df-6a34-42ee-9f41-94fb857f1c29', // Lindenschmidt KG - requested by "Maximilian Nicolussi-Moretto"
        '40a39103-215c-494b-b48b-11d1b5a2f7ad', // Bestlog GmbH - requested by "Maximilian Nicolussi-Moretto"
        '1cea77ad-5ce2-4452-b0af-6cae14ab5f5f', // MAN Truck & Bus Vertrieb Österreich GesmbH - requested by "Maximilian Nicolussi-Moretto"
        '1cd8d1f1-2ee6-41d1-98f6-b7f80c0103e1', // MAN Truck & Bus Schweiz AG - requested by "Maximilian Nicolussi-Moretto"
        '1b9a9697-3d76-405a-b669-b7b58a8eccbe', // Mytransport GmbH - requested by "Maximilian Nicolussi-Moretto"
        '9053e110-0403-48f0-977a-e936412453f5', // Betonwerk Bad Lausick GmbH & Co. KG - requested by "Maximilian Nicolussi-Moretto"
        'fc992e38-f798-4a4c-800d-5deff6855171', // VEGA International Car-Transport and Logistic-Trading GmbH - requested by "Maximilian Nicolussi-Moretto"
        'f02eb393-0d57-4873-9245-5a5f63a5d579', // MAN Truck & Bus Norge AS - requested by "Maximilian Nicolussi-Moretto"
        'f7aff54e-ec32-481e-95e8-4dd1f900352e', // MAN TRUCK & BUS POLSKA SP. Z O.O. - requested by "Maximilian Nicolussi-Moretto"
        'aea3eee6-2e72-4649-9411-2c4a79b99d9f', // MAN Truck og Bus Danmark A/S - requested by "Maximilian Nicolussi-Moretto"
    ];
    return useWhiteListedFeatureToggles(whiteList);
};

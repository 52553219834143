import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import TimedBottomSheet from '@rio-cloud/rio-uikit/TimedBottomSheet';
import IframeResizer from 'iframe-resizer-react';

import { gaPush, trackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleTagManager';

import { DEMO_FLEET_ACCOUNT_ID } from '../../data/dataDefinition';
import { useLocale } from '../../configuration/lang/langSlice';
import { useUserAccount, useUserProfile } from '../../configuration/login/loginSlice';
import { TENANT_RIO_EU_PROD, TENANT_RIO_EU_TEST, useTenant } from '../../configuration/tokenHandling/tokenSlice';

const useElapsedTime = () => {
    const startTimeRef = useRef(Date.now());

    const getElapsedTime = () => {
        return Date.now() - startTimeRef.current;
    };

    useEffect(() => {
        startTimeRef.current = Date.now();
    }, []);

    return getElapsedTime;
};

/*
 * UX research BottomSheet.
 *
 * Note:
 * Don't forget to cleanup the localStorage for the user when removing a feature
 * by setting "cleanupLocalStorage" to "true" and deploy it like this.
 * The cleanup will remove the localStorage flag on mount and will not show the bottom sheet.
 */

const IS_DISABLED = true;

const SHOW_AFTER_IN_MS = 30_000; // 30 seconds
const HIDE_AFTER_IN_MS = 120_000; // 2 minutes

const disallowedAccounts = [DEMO_FLEET_ACCOUNT_ID];

export const UXResearchSurveyBottomSheet = () => {
    const [isDismissed, setIsDismissed] = useState(false);
    const [isPostponed, setIsPostponed] = useState(false);

    const getElapsedTime = useElapsedTime();

    const locale = useLocale();
    const userProfile = useUserProfile();
    const userAccount = useUserAccount();
    const tenant = useTenant();

    if (locale !== 'de-DE') {
        return null;
    }

    const isAllowedForTenants = [TENANT_RIO_EU_PROD, TENANT_RIO_EU_TEST].includes(tenant);

    const url = [
        'https://cloud.news.rio.cloud/lead_generation',
        '?topic=UX_Testing',
        `&locale=${locale}`,
        `&email=${encodeURIComponent(userProfile.email)}`,
        '&hideName=true',
        '&hideEmail=true',
        '&hidePhone=true',
        '&hideNewsletter=true',
        `&buttonText=${encodeURIComponent('Jetzt anmelden')}`,
        `&title=${encodeURIComponent('Sicherheit erhöhen durch Dashcams — Was denken Sie?')}`,
        `&intro=${encodeURIComponent(
            'Gestalten Sie die Zukunft von RIO mit und teilen Sie Ihre Erfahrungen in einem 1-stündigen Interview. Gemeinsam wollen wir spannende Möglichkeiten erkunden und von Ihren Impulsen lernen. ' +
                'Die Interviews starten ab Mitte März – wir freuen uns auf den Dialog mit Ihnen.'
        )}`,
    ].join('');

    if (disallowedAccounts.includes(userAccount) || !isAllowedForTenants) {
        return null;
    }

    const handleCloseBottomSheet = () => {
        setIsDismissed(true);

        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'UX dashcam research survey bottom sheet closed',
            action: `UX dashcam research survey bottom sheet via close button`,
        });

        const elapsedTimeInMilliSeconds = getElapsedTime() - SHOW_AFTER_IN_MS;
        const elapsedTimeInSeconds = elapsedTimeInMilliSeconds / 1000;

        let logTime = 'after more than 10 seconds';

        if (elapsedTimeInSeconds <= 3) {
            logTime = 'in less than 3 seconds';
        } else if (elapsedTimeInSeconds < 5) {
            logTime = 'in less than 5 seconds';
        } else if (elapsedTimeInSeconds < 10) {
            logTime = 'in less than 10 seconds';
        }

        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'UX dashcam survey bottom sheet closed',
            action: `UX dashcam survey bottom sheet via close button ${logTime}`,
        });
    };

    const handlePostpone = () => {
        setIsPostponed(true);
        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'UX dashcam survey bottom sheet postpone',
            action: 'UX dashcam survey bottom sheet postponed',
        });
    };

    if (isPostponed) {
        return null;
    }

    return (
        <TimedBottomSheet
            featureName='UXResearchSurveyBottomSheet'
            dismissed={isDismissed}
            showAfter={SHOW_AFTER_IN_MS}
            hideAfter={HIDE_AFTER_IN_MS}
            onClose={handleCloseBottomSheet}
            bodyClassName='padding-25'
            className='width-600 position-relative overflow-hidden'
            cleanupLocalStorage={IS_DISABLED}
            {...trackingAttributes(TRACKING_CATEGORIES.PROMOTION, 'UX dashcam survey bottom sheet')}
        >
            <div className='margin-top-25 min-height-250'>
                <div className='position-absolute top-10' style={{ right: '45px' }}>
                    <Button bsStyle='muted' bsSize='sm' onClick={handlePostpone}>
                        <FormattedMessage id='intl-msg:livemonitor.label.askMeLater' />
                    </Button>
                </div>
                <div className='display-flex gap-25'>
                    <div className='margin-top-25 padding-top-10 margin-right-10'>
                        <img
                            className='height-100 aspect-ratio-1'
                            src='https://cdn.rio.cloud/riosvg/business-people/communication.svg'
                            alt='communication'
                        />
                    </div>
                    <IframeResizer src={url} checkOrigin={false} style={{ border: '0' }} title='Dashcams survey' />
                </div>
            </div>
        </TimedBottomSheet>
    );
};

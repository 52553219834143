import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../configuration/setup/store';
import { useAppSelector } from '../../../configuration/setup/hooks';

type WidgetDialogContentType = {
    title?: string | undefined;
    size?: 'sm' | 'md' | 'lg' | 'fullscreen';
    url: string;
};

type WidgetDialogState = {
    widgetDialogContent: WidgetDialogContentType | undefined;
};

export const defaultWidgetDialogState: WidgetDialogState = {
    widgetDialogContent: undefined,
};

export const widgetDialogSlice = createSlice({
    name: 'widgetDialog',
    initialState: defaultWidgetDialogState,
    reducers: {
        showWidgetDialog: (state, action: PayloadAction<WidgetDialogContentType | undefined>) => {
            state.widgetDialogContent = action.payload;
        },
        closeWidgetDialog: (state, action: PayloadAction<WidgetDialogContentType | undefined>) => {
            if (action.payload?.url === state.widgetDialogContent?.url) {
                state.widgetDialogContent = undefined;
            }
        },
    },
});

export const { showWidgetDialog, closeWidgetDialog } = widgetDialogSlice.actions;

export const getWidgetDialogContent = (state: RootState): WidgetDialogContentType | undefined =>
    state.app.widgetDialog.widgetDialogContent;

export const useWidgetDialogContent = () => useAppSelector(getWidgetDialogContent);

export default widgetDialogSlice.reducer;

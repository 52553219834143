import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import './configuration/setup/polyfills';
import './configuration/setup/datadog';

import { config } from './config';
import { main } from './configuration';
import { handleLoginRedirect } from './configuration/login/redirect';
import { router } from './routes/Router';

const renderApplication = () => {
    const root = document.getElementById('root') as HTMLElement;

    // Break the pageLoader as soon as possible without waiting for React to render the Application
    root.innerHTML = '<div></div>';

    // Note that we need to use the base "Router" with a "hash" history
    // because the "HashRouter" doesn't allow handing in a history
    // from the outside. So this is effectively a "HashRouter" despite
    // that not being obvious here

    // Enable Sentry React component performance profiler
    // const app = Sentry.withProfiler(AppContainer, { name: 'Livemonitor App' });
    // ReactDOM.render(app, root);

    createRoot(root).render(<RouterProvider router={router} />);
};

const isDev = import.meta.env.DEV;
const isProd = import.meta.env.PROD;
const isProdPreview = import.meta.env.VITE_PRODUCTION_PREVIEW;

if ((isDev && config.enableMockServer) || isProdPreview) {
    Promise.all([
        import('../mocks/serviceMock'),
        import('../mocks/widgets'),
        import('./configuration/setup/store'),
    ]).then(async imports => {
        const [serviceMock, widgets, storeSetup] = imports;
        await serviceMock.worker.start({
            onUnhandledRequest: 'bypass', // This is important to prevent issues with non-mocked requests
            serviceWorker: {
                url: '/mockServiceWorker.js',
                options: {
                    scope: '/',
                },
            },
        });
        widgets.events.forEach(widgetEvent => storeSetup.store.dispatch(widgetEvent));
        main(renderApplication);
    });
}

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else if (isProd && !isProdPreview) {
    main(renderApplication);
}
